const toDate = function (val) {
  return val
    ?.toString()
    ?.replace(/[.]/g, "")
    ?.slice(0, 10)
    ?.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$2-$1");
};

const toServerDate = function (val) {
  return val
    ?.toString()
    ?.replace(/[.-]/g, "")
    ?.slice(0, 8)
    ?.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$2-$1");
};

const dateReverse = function (val, separator) {
  if (val?.length > 10) {
    return val
      ?.replace(/[-]/g, "")
      ?.slice(0, 8)
      ?.replace(/(\d{4})(\d{2})(\d{2})/, `$3${separator}$2${separator}$1 `);
  }
  if (val?.split("-")?.[0].length > 2) {
    return val?.replace(
      /(\d{4})-(\d{2})-(\d{2})/,
      `$3${separator}$2${separator}$1`
    );
  }
  if (val?.split("-")?.[2].length > 2) {
    return val?.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      `$3${separator}$2${separator}$1`
    );
  } else {
    return val?.replace(
      /(\d{4})-(\d{2})-(\d{2})/,
      `$1${separator}$2${separator}$3`
    );
  }
};

const toServerPhone = function (val) {
  return val?.replace(/[+() -]/gi, "");
};

const convertDataToImage = function convertDataToImage(binary) {
  var bytes = new Uint8Array(binary.length);
  for (var i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  var base64String = btoa(String.fromCharCode.apply(null, bytes));
  return base64String;
};

const toRuble = function (val, symbols = 2) {
  return val
    ?.toFixed(symbols)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    ?.replace(/[.]/g, ",");
};

const toServerNumb = function (val) {
  return val.split(" ").join("");
};
const toBase64 = function (val) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1]; // Удаляем заголовок "data:image/jpeg;base64,"

      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(val);
  });
};

export {
  toDate,
  dateReverse,
  toServerPhone,
  toRuble,
  toServerDate,
  toServerNumb,
  toBase64,
  convertDataToImage,
};
