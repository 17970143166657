<template>
  <label
    class="v-checkbox__label"
    :class="{ 'v-checkbox--error': errorBag.length }"
  >
    <input
      type="checkbox"
      :value="value"
      :checked="isChecked"
      @change="onChange"
      class="v-checkbox__input"
    />
    <p class="v-checkbox__error" v-show="errorBag.length">
      {{ errorBag.length ? errorBag?.[0] : "" }}
    </p>
    <slot />
  </label>
</template>

<script>
export default {
  name: "v-checkbox",
  props: {
    value: { type: String, default: null },
    modelValue: { type: Array, default: () => [] },
    errorBag: { type: Array, default: () => [] },
    isDirty: { type: Boolean, default: () => false },
  },
  methods: {
    onChange() {
      if (this.isChecked) {
        this.$emit(
          "update:modelValue",
          this.modelValue.filter((cv) => cv !== this.value)
        );
      } else {
        this.$emit("update:modelValue", [...this.modelValue, this.value]);
      }
    },
  },
  computed: {
    isChecked() {
      return this.modelValue.includes(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-checkbox {
  font-family: "Lato", "Roboto", sans-serif;
  // .v-checkbox__label

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0 !important;
    outline-offset: 0;
    position: relative;
    font-size: 13px;
  }
  // .v-checkbox__text

  &__text {
  }

  // .v-checkbox__input

  &__input {
    margin: 0;
    margin-right: 10px;
    position: relative;
    appearance: none;
    cursor: pointer;
    outline: 0 !important;
    outline-offset: 0;
    overflow: hidden;
    min-width: 20px;
    min-height: 20px;
    &::before {
      content: "";
      display: flex;
      min-width: 20px;
      min-height: 20px;
      border-radius: 5px;
      border: 1px solid $accent;
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.3s;
      background-image: url("~@/assets/svg/success_checkbox_icon.svg");
      background-size: cover;
      width: 12px;
      height: 9px;
      background-repeat: no-repeat;
    }
    &:checked {
      &::before {
        background-color: $accent;
      }
      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  // .v-checkbox--error

  &--error {
    margin-bottom: 16px !important;
    input {
      &::before {
        border-color: $red;
      }
    }
  }

  // .v-checkbox__error

  &__error {
    position: absolute;
    top: 110%;
    left: 30px;
    color: $red;
    font-size: 12px;
    font-family: "Lato", sans-serif;
    max-width: 100%;
    white-space: break-spaces;
  }
}
</style>
