<template>
  <label :for="attrs.id" class="custom-radio-label" tabindex="0">
    <input
      type="radio"
      :name="attrs.name"
      :id="attrs.id"
      :value="value"
      @change="$emit('update:modelValue', $event.target.value)"
      :checked="value === modelValue"
      class="custom-radio"
      tabindex="-1"
    />
    <slot></slot>
  </label>
</template>
<script>
export default {
  name: "v-radio",
  props: {
    modelValue: { type: String, default: null },
    value: { type: String, default: null },
    attrs: { type: Object, default: null },
  },
};
</script>
<style lang="scss">
.custom-radio-label {
  margin-right: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}
.custom-radio {
  margin: 0;
  margin-right: 15px;
  appearance: none;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 50%;
    border: 1px solid $accent;
    min-width: 22px;
    min-height: 22px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $accent;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  &:checked {
    &::after {
      -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
</style>
