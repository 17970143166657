<template>
  <span class="icon" v-html="iconContent" />
</template>

<script>
export default {
  name: "v-icon",
  props: {
    icon: {
      type: [String, Object],
      required: true,
    },
    to: {
      type: String,
      default: () => null,
    },
  },

  data() {
    return {
      iconContent: "",
    };
  },

  watch: {
    icon: {
      immediate: true,
      handler(val) {
        if (typeof val === "string") {
          // если передан путь к файлу SVG
          fetch(val)
            .then((response) => response.text())
            .then((svg) => {
              this.iconContent = svg;
            });
        } else {
          // иначе считаем, что передано содержимое SVG
          this.iconContent = val;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 0;
}
</style>
