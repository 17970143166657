const required = (field) => {
  field.isDirty = true;
  if (field?.attrs?.type === "radio") {
    if (!field.value.length) {
      field.errorBag.unshift("Необходимо отметить");
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item !== "Необходимо отметить"
      );
    }
    return;
  }
  if (
    typeof field.value == "Array" ||
    (typeof field.value == "object" && typeof field?.value?.value !== "string")
  ) {
    if (!field.value.length) {
      field.errorBag.unshift("Необходимо отметить поле");
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item !== "Необходимо отметить поле"
      );
    }
    field.errorBag = [...new Set(field.errorBag)];
    return;
  } else {
    if (field.value == "" || field.length <= 0) {
      field.errorBag.unshift("Поле обязательно для заполнения");
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item !== "Поле обязательно для заполнения"
      );
    }
    field.errorBag = [...new Set(field.errorBag)];
    return;
  }
};

const fileRequired = (field) => {
  field.isDirty = true;
  if (!field.value?.name) {
    field.errorBag.push(`Загрузите файл`);
  } else {
    field.errorBag = field.errorBag.filter((item) => item !== "Загрузите файл");
  }
};

const checkGreaterNum = (field, num1, num2, errorText) => {
  field.isDirty = true;
  if (+num1 > num2) {
    field.errorBag.push(errorText);
  } else {
    field.errorBag = field.errorBag.filter((item) => item !== errorText);
  }
};

const validateFileType = (field) => {
  field.isDirty = true;
  const patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  const file_type = field.value?.name?.match(patt1)[0];
  if (
    file_type !== ".png" &&
    file_type !== ".jpg" &&
    file_type !== ".jpeg" &&
    file_type !== ".pdf"
  ) {
    field.errorBag.push(
      `Вы загрузили ${file_type} файл, допустим png/jpg/jpeg/pdf`
    );
  } else {
    field.errorBag = field.errorBag.filter(
      (item) => !item.includes("допустим png/jpg/jpeg/pdf")
    );
  }
};

const validateSize = (field) => {
  field.isDirty = true;
  const file_size = field.value.size;
  if (file_size > 10485760) {
    field.errorBag.push(`Вес файла превышает 10мб`);
  } else {
    field.errorBag = field.errorBag.filter(
      (item) => item !== "Вес файла превышает 10мб"
    );
  }
};

const validateSymbols = (field) => {
  field.isDirty = true;
  const hasNumber = /\d/.test(field.value); // Проверяем наличие цифры
  const hasLetter = /[a-zA-Z]/.test(field.value); // Проверяем наличие буквы
  if (!hasNumber || !hasLetter) {
    field.errorBag.push(`Должно содержать одну цифру и букву`);
  } else {
    field.errorBag = field.errorBag.filter(
      (item) => item !== "Должно содержать одну цифру и букву"
    );
  }
};

const sameAs = (field, compare) => {
  field.isDirty = true;
  if (field.value !== compare) {
    field.errorBag.push(`Не совпадает с ранее придуманным паролем`);
  } else {
    field.errorBag = field.errorBag.filter(
      (item) => item !== "Не совпадает с ранее придуманным паролем"
    );
  }
};

const validateMinLength = (field, length, errorLength, min = false) => {
  field.isDirty = true;
  field.errorBag = [...new Set(field.errorBag)];
  if (field.value.length < length) {
    field.errorBag.unshift(
      `Должен состоять ${min ? "минимум" : ""} из ${errorLength} символов`
    );
  } else {
    field.errorBag = field.errorBag.filter(
      (item) =>
        item !==
        `Должен состоять ${min ? "минимум" : ""} из ${errorLength} символов`
    );
  }
  field.errorBag = [...new Set(field.errorBag)];
};

const minor = (field) => {
  field.isDirty = true;
  const year = field.value.split(".")?.[2];
  const month = field.value.split(".")?.[1];
  const date = field.value.split(".")?.[0];
  field.errorBag = [...new Set(field.errorBag)];
  if (
    +month > 12 ||
    +date > 31 ||
    +year < 1900 ||
    +year >= 2024 ||
    month === "00" ||
    date === "00"
  ) {
    field.errorBag.unshift(`Недопустимая дата`);
  } else {
    field.errorBag = field.errorBag.filter(
      (item) => item !== `Недопустимая дата`
    );
  }
  field.errorBag = [...new Set(field.errorBag)];
};

const emailRequired = (field) => {
  let regex = /^\S+@\S+\.\S+$/,
    emailRegex = /^[a-zA-Z0-9._%+-]+@.*\.[a-zA-Z]{2,}$/,
    filteredVal = field.value.match(emailRegex);

  // if (filteredVal == null || filteredVal?.length <= 0) {
  //   field.value = "";
  // } else {
  //   field.value = filteredVal.join("");
  // }
  field.errorBag = [...new Set(field.errorBag)];
  if (!emailRegex.test(field.value.toLowerCase())) {
    field.errorBag.unshift("Недопустимый формат почты");
  } else {
    field.errorBag = field.errorBag.filter((item) => {
      return item !== "Недопустимый формат почты";
    });
  }
};

const addressValidate = (field) => {
  field.isDirty = true;
  let currSuggestion;
  setTimeout(() => {
    currSuggestion =
      field.suggestions.filter(
        (item) => item.value?.toLowerCase() === field.value?.toLowerCase()
      )?.[0]?.data || {};
    field.errorBag = [...new Set(field.errorBag)];
    if (!currSuggestion?.house) {
      field.errorBag.unshift(`Выберите полный адрес из подсказок`);
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item !== `Выберите полный адрес из подсказок`
      );
    }
    field.errorBag = [...new Set(field.errorBag)];
  }, 1200);
};

export {
  required,
  validateMinLength,
  validateFileType,
  validateSize,
  fileRequired,
  minor,
  validateSymbols,
  sameAs,
  checkGreaterNum,
  addressValidate,
  emailRequired,
};
