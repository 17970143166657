import vIcon from "@/components/UI/v-icon";
import vRange from "@/components/UI/v-range";
import vPopup from "@/components/UI/v-popup";
import vInput from "@/components/UI/v-input";
import vCheckbox from "@/components/UI/v-checkbox";
import vRadio from "@/components/UI/v-radio";
import vBtn from "@/components/UI/v-btn";
import vSelect from "@/components/UI/v-select";
import vToggle from "@/components/UI/v-toggle";
import vHeader from "@/components/UI/v-header";
import vFooter from "@/components/UI/v-footer";
import vAccordeon from "@/components/UI/v-accordeon";
import vNotification from "@/components/UI/v-notification";

export default [
  vIcon,
  vRange,
  vPopup,
  vInput,
  vCheckbox,
  vRadio,
  vBtn,
  vSelect,
  vToggle,
  vHeader,
  vAccordeon,
  vFooter,
  vNotification,
];
