<template>
  <div class="default-layout default">
    <v-header></v-header>
    <div class="test" v-show="!routerViewLoaded"></div>
    <router-view />
    <v-footer></v-footer>
  </div>
</template>
<script>
export default {
  name: "default-layout",
  data() {
    return { routerViewLoaded: false };
  },
  watch: {},
  mounted() {
    setTimeout(() => {
      this.routerViewLoaded = true;
    }, 100);
  },
};
</script>
<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex: 1 1 auto;
  }
}
.default {
}
</style>
