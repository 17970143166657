<template>
  <footer class="v-footer">
    <div class="v-footer__container">
      <p class="v-footer__logo" @click="this.$router.push('/')">acado</p>
      <v-icon
        class="v-footer__payment-icon"
        :icon="require(`@/assets/svg/b2p-logo.svg`)"
      />
      <div class="v-footer__docs-list">
        <div class="v-footer__docs-column">
          <a
            v-for="(item, index) in footer_docs1"
            :key="index"
            class="v-footer__document"
            target="_blank"
            :href="`./${item.name}.pdf`"
          >
            {{ item.name }}
          </a>
        </div>
        <div class="v-footer__docs-column">
          <a
            v-for="(item, index) in footer_docs2"
            :key="index"
            class="v-footer__document"
            target="_blank"
            :href="`./${item.name}.pdf`"
          >
            {{ item.name }}
          </a>
        </div>
        <div class="v-footer__docs-column">
          <a
            v-for="(item, index) in footer_docs3"
            :key="index"
            class="v-footer__document"
            target="_blank"
            :href="`./${item.name}.pdf`"
          >
            {{ item.name }}
          </a>
          <a class="v-footer__mail" href="mailto:prav.konsalt@yandex.ru">
            ООО "ПРАВО КОНСАЛТ" -
            <span>prav.konsalt@yandex.ru </span>

            <a class="v-footer__mail" href="tel:+79613876746">
              <span>+79613876746</span>
            </a>
          </a>
          <a
            v-for="(item, index) in footer_docs4"
            :key="index"
            class="v-footer__document"
            target="_blank"
            :href="`./${item.name}.pdf`"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
      <!-- <div class="charter__list">
        <a
          v-for="(item, index) in charter_docs"
          :key="index"
          class="v-footer__document"
          target="_blank"
          :href="`./${item.name}.pdf`"
        >
          {{ item.name }}
        </a>
      </div> -->
      <p class="v-footer__license">
        Деятельность осуществляет Общество с ограниченной ответственностью
        Микрокредитная компания «АКАДО» (ООО МКК «АКАДО»), номер в реестре МФО
        2203045009929, информация внесена в реестр МФО 21.11.2022 года
      </p>
      <p class="v-footer__license"><span>ИНН</span> 9726022441</p>
      <!-- <p class="v-footer__license"><span>КПП</span> 772601001</p> -->
      <p class="v-footer__license"><span>ОГРН</span> 1227700605032</p>
      <p class="v-footer__license">
        Юридический адрес: 443090, ГОРОД САМАРА, УЛИЦА СОВЕТСКОЙ АРМИИ, ДОМ 180/3, ОФИС 808
      </p>
      <p class="v-footer__license">
        Почтовый и фактический адрес: 443090, город Самара, улица Советской
        Армии, дом 180/3, офис 808
      </p>
      <p class="v-footer__license">
        <span>р/с</span> 40701810203900000001 в ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ
        (ПАО) БИК 044525411 Корр.счет 30101810145250000411
      </p>
      <p class="v-footer__license">
        Генеральный директор Журавлева Ольга Владимировна на основании Устава с
        23.09.2022г.
      </p>
      <pre class="v-footer__license"></pre>
      <p class="v-footer__license">
        Является участником Союза «Микрофинансовый Альянс «Институты развития
        малого и среднего бизнеса», дата вступления 10.02.2023 года. Адрес: г
        125367, РОССИЯ, Г. МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ
        ПОКРОВСКОЕ-СТРЕШНЕВО, ПОЛЕССКИЙ ПР-Д, Д. 16, СТР. 1, ПОМЕЩ./ЭТ.
        308/АНТРЕСОЛЬ
      </p>
      <p class="v-footer__license">
        ООО МКК «Акадо» не привлекает денежные средства физических лиц, в том
        числе индивидуальных предпринимателей, не являющихся учредителями
        организации ООО МКК «Акадо», номер в реестре МФО 2203045009929
      </p>
      <p class="v-footer__license">
        Выдача займов осуществляется ежедневно, с 8:00 до 20:00 по МСК
      </p>
      <div class="v-footer__contacts">
        <v-btn
          class="v-footer__feedback"
          @click="openDialog('feedback_dialog')"
        >
          Пожаловаться
        </v-btn>
        <a class="v-footer__contanct" href="mailto:kc@acado.market">
          Контактный email для приема обращений: <span>kc@acado.market</span>
        </a>
        <a class="v-footer__contanct" href="tel:+79626058799">
          Контактный телефон для приема обращений: <span>79626058799</span>
        </a>
        <pre class="v-footer__contanct">
Режим работы<span>
Пн-Пт
с 9-00 до 18-00
</span>
        </pre>
      </div>
      <v-popup
        @closePopup="closeDialog('feedback_dialog')"
        class="feedback__dialog"
        :visible="feedback_dialog.visible"
      >
        <template #title>
          {{ feedback_dialog.title }}
        </template>
        <template #default>
          <p class="feedback__dialog-descr">
            Оставьте контакты для обратной связи
          </p>
          <p class="feedback__dialog-descr">
            Ответ организации в этой форме не предусмотрен как обязательный. Для скорейшего решения вашего вопроса обратитесь в чат.
          </p>

          <v-input
            :attrs="feedback_form.fields.fio.attrs"
            v-model="feedback_form.fields.fio.value"
            class="feedback__dialog-inp"
            :errorBag="feedback_form.fields.fio.errorBag"
          />
          <v-input
            :attrs="feedback_form.fields.phone.attrs"
            v-model="feedback_form.fields.phone.value"
            class="feedback__dialog-inp"
            :errorBag="feedback_form.fields.phone.errorBag"
          />
          <v-input
            :attrs="feedback_form.fields.msg.attrs"
            v-model="feedback_form.fields.msg.value"
            class="feedback__dialog-inp"
          />
          <v-btn class="feedback__dialog-btn" @click="sendFeedback"
            >Отправить</v-btn
          >
        </template>
      </v-popup>
    </div>
  </footer>
</template>
<script>
import { required, validateMinLength } from "../../utils/validates";
import { toServerPhone } from "../../utils/converters";
import { mapActions } from "vuex";
export default {
  name: "v-footer",
  data() {
    return {
      payments: ["visa", "mastercard", "maestro", "mir"],
      feedback_form: {
        step: 0,
        fields: {
          fio: {
            value: "",
            attrs: {
              id: "fio_feedback",
              placeholder: "Введите ФИО",
              label: "ФИО",
              maska: {
                mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
                tokens: { H: { pattern: /[А-Яа-я ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          msg: {
            value: "",
            attrs: {
              id: "msg_feedback",
              placeholder: "Введите текст обращения",
              label: "Текст обращения",
              maska: {
                mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
                tokens: { H: { pattern: /[А-Яа-я-/,0-9 ]/ } },
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: "",
            attrs: {
              id: "phone_feedback",
              label: "Ваш номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      feedback_dialog: {
        visible: false,
        title: "Обратная связь",
      },
      footer_docs: [],
      footer_docs1: [
        { name: "Свидетельство о постановке на учет в налоговый орган" },
        { name: "Выписка из реестра МФО" },
        {
          name: "Лист записи ЕГРЮЛ",
        },
        {
          name: "Выписка из реестра СРО",
        },
        {
          name: "Устав",
        },
        {
          name: "Список участников и лиц, под контролем либо значительным влиянием которых находится МКК",
        },
        { name: "Информация о персональном составе органов управления" },
        { name: "Соглашение об использовании АСП" },
        {
          name: "Базовый стандарт защиты прав и интересов физических и юридических лиц - получателей финансовых услуг",
        },
      ],
      footer_docs2: [
        {
          name: "Информация об условиях предоставления, использования и возврата потребительского займа",
        },
        {
          name: "Правила предоставления микрозаймов",
        },
        { name: "Общие условия договора потребительского займа" },
        { name: "Политика конфиденциальности" },
        { name: "Политика обработки и защиты персональных данных" },
        {
          name: "Положение о требованиях к содержанию обращений получателей финансовых услуг",
        },
        { name: "Информация о финансовых продуктах ООО МКК АКАДО" },
        { name: "Рекомендации клиентам по защите информации" },
        { name: "Условия предоставление кредитных каникул" },
      ],
      footer_docs3: [
        { name: "Информация по кредитным каникулам по мобилизации" },
        { name: "Политика безопасности b2p" },
        {
          name: "Брошюра Банка России о кредитных каникулах для мобилизованных",
        },
        { name: "Правила оказания услуг Телемедицина" },
        { name: "Правила оказания услуг Телеюрист" },
        { name: "Устав ООО «Право консалт»" },
        { name: "Оферта на оказание услуг «Право консалт»" },
        // { name: "Реквизиты" },
      ],
      footer_docs4: [
        { name: "Устав ООО «Право консалт»" },
        { name: "Оферта на оказание услуг «Право консалт»" },
      ],
    };
  },
  methods: {
    ...mapActions(["addNotification", "SEND_FEEDBACK"]),
    openDialog(dialog) {
      this[dialog].visible = true;
    },
    closeDialog(dialog) {
      this[dialog].visible = false;
    },
    async sendFeedback() {
      Object.keys(this.feedback_form.fields).map((item) => {
        if (this.feedback_form.fields[item]?.validate) {
          Object.values(this.feedback_form.fields[item].validate).map((el) => {
            el.fn(this.feedback_form.fields[item], ...el.args);
          });
        }
      });

      if (this.feeback_valid) {
        const payload = {
          phone: toServerPhone(this.feedback_form.fields.phone.value),
          fio: this.feedback_form.fields.fio.value,
          message: this.feedback_form.fields.msg.value,
        };
        await this.SEND_FEEDBACK(payload).then((res) => {
          const status = res?.data?.code || res?.status;
          if (status === 200) {
            this.closeDialog("feedback_dialog");
            this.addNotification({
              message: "Форма успешно отправлена",
            });
          } else {
            this.addNotification({
              message: "Что-то пошло не так",
            });
          }
        });
      }
    },
  },
  computed: {
    feeback_valid() {
      if (
        Object.values(this.feedback_form.fields).filter(
          (item) => item?.errorBag?.length || (!item?.isDirty && item?.validate)
        ).length
      ) {
        return false;
      } else return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-footer {
  background-color: $dark-blue;
  /* .v-footer__container */

  &__container {
    max-width: 1158px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  /* .v-footer__logo */

  &__logo {
    position: relative;
    z-index: 2;
    font-size: 36px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    white-space: nowrap;
    color: $accent;
    cursor: pointer;
  }

  /* .v-footer__payment-icon */

  &__payment-icon {
    margin-top: 12px;
  }

  /* .v-footer__contacts */

  &__contacts {
    display: flex;
    padding: 12px 0;
    flex-direction: column;
    color: $white;
  }

  /* .v-footer__contanct */

  &__contanct {
    font-family: "Manrope";
    margin: 0;
    margin-bottom: 12px;
    margin-right: 50px;
    color: inherit;
    font-weight: 600;
    span {
      color: $accent;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  /* .v-footer__license */

  &__license {
    font-family: "Manrope";
    width: 100%;
    margin-top: 10px;
    line-height: 130%;
    color: $white;
    margin-bottom: 0;
    span {
      font-weight: 600;
    }
  }

  /* .v-footer__feedback */

  &__feedback {
    margin: 6px 0;
  }

  /* .v-footer__info*/

  &__info {
    color: $white;
  }

  /* .v-footer__document */

  &__document {
    color: $white;
    cursor: pointer;
    margin: 6px 0;
    text-decoration: underline;
  }
}
.v-footer {
  font-size: 12px;
  /* .v-footer__docs-column */

  &__docs-column {
    display: flex;
    flex-direction: column;
  }
  /* .v-footer__docs-list */

  &__docs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  /* .v-footer__document */

  &__document {
    max-width: 300px;
    font-size: 12px;
  }

  /* .v-footer__mail */

  &__mail {
    max-width: 300px;
    font-size: 12px;
    color: $white;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
@media (max-width: 619px) {
  .v-footer {
    /* .v-footer__docs-column */

    &__docs-column {
    }
    /* .v-footer__docs-list */

    &__docs-list {
    }

    /* .v-footer__document */

    &__document {
      max-width: initial;
    }

    /* .v-footer__mail */

    &__mail {
      max-width: initial;
    }
  }
}
@media (max-width: 575px) {
  .v-footer {
    /* .v-footer__container */

    &__container {
    }

    /* .v-footer__logo */

    &__logo {
    }

    /* .v-footer__payment-icon */

    &__payment-icon {
    }

    /* .v-footer__contacts */

    &__contacts {
      flex-direction: column;
    }

    /* .v-footer__contanct */

    &__contanct {
    }

    /* .v-footer__license */

    &__license {
    }
  }
}
.feedback__dialog-descr {
  font-size: 13px;
  margin-bottom: 12px;
}
.feedback__dialog-btn {
}
.feedback__dialog-inp {
  margin-bottom: 12px;
}
</style>
