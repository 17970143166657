<template>
  <div class="v-accordion-item">
    <div class="v-accordion-item__label" ref="label" @click="toggleAccordion">
      <slot name="label" />
      <v-icon
        class="v-accordion-item__icon"
        ref="accordion_icon"
        :icon="require(`@/assets/svg/accordeon.svg`)"
      />
    </div>
    <div class="v-accordion-item__text" ref="text">
      <p><slot name="text" /></p>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "v-accordeon",
  data() {
    return {
      expanded: false,
      textHeight: 0,
      iconName: "arrow-down",
    };
  },
  methods: {
    toggleAccordion() {
      this.expanded = !this.expanded;
      gsap.to(this.$refs.text, 0.1, {
        height: this.expanded ? this.textHeight : 0,
      });
      gsap.to(this.$refs.accordion_icon.$el, 0.4, {
        rotation: this.expanded ? 180 : 0,
      });
    },
  },
  mounted() {
    this.textHeight = this.$refs.text.scrollHeight;
    this.$refs.text.style.height = "0";
  },
};
</script>

<style lang="scss" scoped>
.v-accordion-item {
  width: 100%;
  border-radius: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0px 4px 16px rgba(193, 193, 193, 0.25);
  /* .v-accordion-item__label */

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 15px;
    cursor: pointer;
    line-height: 100%;
    font-size: 18px;
  }

  /* .v-accordion-item__icon */

  &__icon {
    max-width: 22px;
    width: 100%;
  }

  /* .v-accordion-item__text */

  &__text {
    padding: 0px 15px;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
    background-color: $white;
    font-size: 15px;
    p {
      padding-bottom: 20px;
      line-height: 130%;
      color: $third-grey;
    }
    &::slotted(*) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
// @media (max-width: 768px) {
//   .v-accordion-item {
//     /* .v-accordion-item__label */

//     &__label {
//       padding: 22px 20px;
//     }

//     /* .v-accordion-item__text */

//     &__text {
//       padding: 0;
//       p {
//         padding: 0px 22px;
//         padding-bottom: 20px;
//       }
//       &::slotted(*) {
//       }
//     }
//   }
// }
</style>
