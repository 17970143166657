<template>
  <header class="v-header">
    <nav class="v-header__nav">
      <div class="v-header__menu" @click="toggleMenu" ref="menu">
        <span class="v-header__menu-line"></span>
        <span class="v-header__menu-line"></span>
        <span class="v-header__menu-line"></span>
      </div>
      <p class="v-header__logo" @click="this.$router.push('/')">acado</p>
      <!-- <v-icon class="v-header__logo" :icon="require(`@/assets/svg/logo.svg`)" /> -->
      <div
        class="v-header__wrapper"
        :class="{ 'v-header__wrapper--active': menuToggle }"
        ref="wrapper"
      >
        <div class="v-header__links">
          <a
            href="#getLoan"
            @click="this.$router.push({ path: '/', hash: '#getLoan' })"
            class="v-header__link"
          >
            Как получить займ
          </a>
          <a
            href="#about"
            @click="this.$router.push({ path: '/', hash: '#about' })"
            class="v-header__link"
          >
            О компании
          </a>
          <a
            href="#getLoan"
            @click="this.$router.push({ path: '/', hash: '#getLoan' })"
            class="v-header__link"
          >
            Условия займа
          </a>
          <a
            href="#about"
            @click="this.$router.push({ path: '/', hash: '#about' })"
            class="v-header__link"
          >
            Вопросы и ответы
          </a>
        </div>
        <div class="v-header__actions">
          <v-btn class="v-header__lk" @click="openDialog('feedback_dialog')">
            Заказать звонок
          </v-btn>
          <v-btn
            class="v-header__lk"
            @click="toggleMenu(), openDialog('signin_dialog')"
          >
            Личный кабинет
          </v-btn>
        </div>
      </div>
      <v-btn class="v-header__btn" @click="openDialog('feedback_dialog')"
        >Заказать звонок</v-btn
      >
      <v-btn class="v-header__auth" @click="openDialog('signin_dialog')">
        {{ authorized ? "Выйти" : "Войти" }}
      </v-btn>
    </nav>
    <v-popup
      @closePopup="closeDialog('signin_dialog')"
      class="signin__dialog"
      :visible="signin_dialog.visible"
    >
      <template #title>
        {{ signin_dialog.title }}
      </template>
      <template #default>
        <p class="signin__dialog-descr" v-show="signin_form.step == 1">
          На номер <span>{{ signin_form.fields.phone.value }}</span> был
          отправлен СМС код для входа в личный кабинет
        </p>
        <v-input
          :attrs="signin_form.fields.phone.attrs"
          v-model="signin_form.fields.phone.value"
          class="signin__dialog-inp"
          :errorBag="signin_form.fields.phone.errorBag"
        />
        <v-input
          :attrs="signin_form.fields.password.attrs"
          v-model="signin_form.fields.password.value"
          class="signin__dialog-inp"
          :errorBag="signin_form.fields.password.errorBag"
        />
        <p
          class="signin__dialog-reset"
          @click="closeDialog('signin_dialog'), openDialog('reset_dialog')"
        >
          Восстановить пароль
        </p>
        <v-btn class="signin__dialog-btn" @click="signin">Войти</v-btn>
      </template>
    </v-popup>
    <v-popup
      @closePopup="closeDialog('reset_dialog')"
      class="signin__dialog"
      :visible="reset_dialog.visible"
    >
      <template #title>
        {{ reset_dialog.title }}
      </template>
      <template #default>
        <p class="signin__dialog-descr" v-show="reset_form.step == 1">
          На номер <span>{{ reset_form.fields.phone.value }}</span> был
          отправлен СМС код для восстановления пароля
        </p>

        <v-input
          v-show="reset_form.step == 0"
          :attrs="reset_form.fields.phone.attrs"
          v-model="reset_form.fields.phone.value"
          class="signin__dialog-inp"
          :errorBag="reset_form.fields.phone.errorBag"
        />
        <v-checkbox
          v-model="reset_recaptcha_token.value"
          @change="recaptcha('recaptchaResetRef', reset_recaptcha_token)"
          value="Принимаю"
          ref="recaptchaResetRef"
          class="acceptDocs form__checkbox"
          v-show="reset_form.step == 0"
        >
          Я не робот
        </v-checkbox>
        <v-input
          v-show="reset_form.step == 1"
          :attrs="reset_form.fields.sms_code.attrs"
          v-model="reset_form.fields.sms_code.value"
          class="signin__dialog-inp"
          :errorBag="reset_form.fields.sms_code.errorBag"
        />
        <v-input
          v-show="reset_form.step == 2"
          :attrs="reset_form.fields.password.attrs"
          v-model="reset_form.fields.password.value"
          class="signin__dialog-inp"
          :errorBag="reset_form.fields.password.errorBag"
        />
        <v-btn class="signin__dialog-btn" @click="reset">
          Отправить код подтверждения
        </v-btn>
      </template>
    </v-popup>
    <v-popup
      @closePopup="closeDialog('feedback_dialog')"
      class="feedback__dialog"
      :visible="feedback_dialog.visible"
    >
      <template #title>
        {{ feedback_dialog.title }}
      </template>
      <template #default>
        <p class="feedback__dialog-descr">
          Оставьте контакты для обратной связи
        </p>
        <v-input
          :attrs="feedback_form.fields.fio.attrs"
          v-model="feedback_form.fields.fio.value"
          class="feedback__dialog-inp"
          :errorBag="feedback_form.fields.fio.errorBag"
        />
        <v-input
          :attrs="feedback_form.fields.phone.attrs"
          v-model="feedback_form.fields.phone.value"
          class="feedback__dialog-inp"
          :errorBag="feedback_form.fields.phone.errorBag"
        />
        <v-input
          :attrs="feedback_form.fields.msg.attrs"
          v-model="feedback_form.fields.msg.value"
          class="feedback__dialog-inp"
        />
        <v-btn class="feedback__dialog-btn" @click="sendFeedback">
          Отправить
        </v-btn>
      </template>
    </v-popup>
  </header>
</template>
<script>
import { required, validateMinLength } from "../../utils/validates";
import gsap from "gsap";
import { mapActions, mapGetters } from "vuex";
import { toServerPhone } from "../../utils/converters";
import md5 from "md5";
export default {
  name: "v-header",
  components: {},
  data() {
    return {
      menuToggle: false,
      signedIn: false,
      signin_dialog: {
        visible: false,
        title: "Вход в личный кабинет",
      },
      reset_dialog: {
        visible: false,
        title: "Восстановление пароля",
      },
      feedback_dialog: {
        visible: false,
        title: "Обратная связь",
      },
      signin_form: {
        step: 0,
        fields: {
          password: {
            value: "",
            attrs: {
              id: "password",
              placeholder: "Введите пароль",
              type: "password",
              label: "Пароль",
              maska: {
                mask: "HHHHHHHHHHHHHHHHHHHHHHH",
                tokens: { H: { pattern: /[A-Z-a-z0-9 ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: "",
            attrs: {
              id: "login_phone",
              label: "Ваш номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      reset_form: {
        step: 0,
        fields: {
          password: {
            value: "",
            attrs: {
              id: "reset_password",
              placeholder: "Введите новый пароль",
              type: "password",
              label: "Пароль",
              maska: {
                mask: "HHHHHHHHHHHHHHHHHHHHHHH",
                tokens: { H: { pattern: /[A-Z-a-z0-9 ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: "",
            attrs: {
              id: "reset_phone",
              label: "Ваш номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          sms_code: {
            value: "",
            attrs: {
              id: "reset_sms_code",
              placeholder: "Введите код подтверждения",
              label: "Код подтверждения",
              maska: {
                mask: "####",
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [4, 4],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      feedback_form: {
        step: 0,
        fields: {
          fio: {
            value: "",
            attrs: {
              id: "fio_feedback_header",
              placeholder: "Введите ФИО",
              label: "ФИО",
              maska: {
                mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
                tokens: { H: { pattern: /[А-Яа-я ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          msg: {
            value: "",
            attrs: {
              id: "message_feedback",
              placeholder: "Введите текст обращения",
              label: "Текст обращения",
              maska: {
                mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
                tokens: { H: { pattern: /[А-Яа-я-/,0-9 ]/ } },
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: "",
            attrs: {
              id: "phone_feedback_header",
              label: "Ваш номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      need_recaptcha: false,
      login_recaptcha_token: {
        value: [],
        token: "",
        validate: {
          required: {
            fn: required,
            args: [],
          },
        },
        errorBag: [],
        isDirty: false,
      },
      reset_recaptcha_token: {
        value: [],
        token: "",
        validate: {
          required: {
            fn: required,
            args: [],
          },
        },
        errorBag: [],
        isDirty: false,
      },
    };
  },
  methods: {
    ...mapActions([
      "TEST",
      "LOGIN",
      "SEND_FEEDBACK",
      "addNotification",
      "RESET_PASSWORD",
    ]),
    toggleMenu() {
      this.menuToggle = !this.menuToggle;
    },
    initMenu() {
      const menu = this.$refs?.menu;
      const wrapper = this.$refs?.wrapper;
      const line = menu?.children[0];
      const line2 = menu?.children[1];
      const line3 = menu?.children[2];
      this.menuAnimation = gsap.timeline({ paused: true });
      this.menuAnimation?.to(line2, 0.2, { opacity: 0 });
      this.menuAnimation?.to(line, 0.4, { top: "50%", rotate: "45deg" });
      this.menuAnimation?.to(
        line3,
        0.4,
        { top: "50%", rotate: "-45deg" },
        "-=0.4"
      );
      this.menuAnimation?.to(wrapper, { x: 0 }, "-=0.4");
    },
    async recaptcha(ref, data) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute("6Ldy0vcmAAAAAEi0lP7_ifBorQRLeecPFTtrkaUE", {
            action: "submit",
          })
          .then((token) => {
            this.$refs[ref].$el.style.pointerEvents = "none";

            if (token !== "" && token !== undefined && token !== null) {
              data.token = token;
              localStorage.setItem("reset_recaptcha_token", token);
            } else {
              data.value = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    openDialog(dialog) {
      if (dialog == "signin_dialog" && this.authorized) {
        this.$router.push("/");
        this.$store.state.dashboard.token = "";
        this.$store.state.dashboard.active_orders = [];
      } else this[dialog].visible = true;
    },
    closeDialog(dialog) {
      console.log(dialog);
      this[dialog].visible = false;
    },
    reset() {
      const step = this.reset_form.step;
      console.log(step);
      // this.reset_form.step++;
      if (step === 0) {
        this.reset_form.fields.phone.validate.required.fn(
          this.reset_form.fields.phone
        );
        this.reset_form.fields.phone.validate.validateMinLength.fn(
          this.reset_form.fields.phone,
          18,
          11
        );
      }

      if (step === 1) {
      }

      if (step === 2) {
        this.reset_form.fields.password.validate.required.fn(
          this.reset_form.fields.password
        );
      }

      this.$nextTick(async () => {
        let payload = {};
        if (
          this.reset_phone_valid &&
          this.reset_recaptcha_valid &&
          step === 0
        ) {
          const payload_phone = toServerPhone(
            this.reset_form.fields.phone.value
          );
          payload.phone = payload_phone;
          payload.hash = md5(
            `${payload_phone}_Pa$$word!${payload_phone}Acado_Nfkrldirjdh_${payload_phone}`
          );

          this.RESET_PASSWORD({ step, payload })
            .then((res) => {
              console.log(res);
              if (res?.data?.code === 200) {
                this.reset_form.step++;
                if(res.data.data?.client_id) {
                  localStorage.setItem("user_id", res.data.data.client_id);
                }
              }
            })
            .catch((err) => {
              if (err?.response?.status === 500)
                this.addNotification({ message: "Неизвестная ошибка" });
              else
                this.addNotification({ message: err?.response?.data?.message });
              console.log(err);
            });
        }
        if (this.reset_phone_valid && step === 1) {
          payload.code = this.reset_form.fields.sms_code.value;
          payload.user_id = localStorage.getItem("user_id");
          this.RESET_PASSWORD({ step, payload })
            .then((res) => {
              console.log(res);
              if (res?.data?.data?.is_confirmed) {
                this.reset_form.step++;
              }
              if (!res?.data?.data?.is_confirmed) {
                this.addNotification({ message: "Проверьте код" });
              }
            })
            .catch((err) => {
              if (err?.response?.status === 500)
                this.addNotification({ message: "Неизвестная ошибка" });
              else
                this.addNotification({ message: err?.response?.data?.message });
              console.log(err);
            });
        }
        if (this.reset_phone_valid && step === 2) {
          payload.password = this.reset_form.fields.password.value;
          payload.user_id = localStorage.getItem("user_id");
          payload.code = this.reset_form.fields.sms_code.value;
          this.RESET_PASSWORD({ step, payload })
            .then((res) => {
              console.log(res);
              if (res?.data?.code === 200) {
                this.addNotification({ message: "Пароль успешно изменён" });
                this.signin_form.fields.phone.value =
                  this.reset_form.fields.phone.value;
                this.closeDialog("reset_dialog");
                this.openDialog("signin_dialog");
              }
            })
            .catch((err) => {
              console.log(err);
              if (err?.response?.status === 500) {
                this.addNotification({ message: "Неизвестная ошибка" });
              } else if (
                err?.response?.status === 422 &&
                err?.response?.data?.message.includes("8 characters")
              ) {
                this.addNotification({
                  message: "Должен содержать минимум 8 символов",
                });
              } else if (err?.response?.status === 422) {
                this.addNotification({
                  message: err?.response?.data?.message,
                });
              } else {
                this.addNotification({ message: err?.response?.data?.message });
              }
            });
        }
      });
    },
    async signin() {
      this.signin_form.fields.phone.validate.required.fn(
        this.signin_form.fields.phone
      );
      this.signin_form.fields.phone.validate.validateMinLength.fn(
        this.signin_form.fields.phone,
        18,
        11
      );

      this.signin_form.fields.password.validate.required.fn(
        this.signin_form.fields.password
      );

      this.$nextTick(async () => {
        if (this.phone_valid && this.password_valid) {
          let payload = {
            phone: toServerPhone(this.signin_form.fields.phone.value),
            password: this.signin_form.fields.password.value,
          };

          let res = await this.LOGIN(payload);
          if (res.data.code === 200) {
            this.$router.push({
              path: "/loan",
              query: { user_id: res.data.data.client_id || "test" },
            });
            return;
          }
          if (res.data.code === 408) {
            this.addNotification({ message: "Необходимо пройти капчу" });
          }
          console.log(res?.data?.code);
          if (res?.data?.code === 422) {
            const sum = localStorage.getItem("sum"),
              days = localStorage.getItem("days");
            this.$store?.commit("SET_TOKEN", res?.data?.data?.token);
            this.closeDialog("signin_dialog");
            this.$router.push({
              path: `/signup/${this.signin_form.fields.phone.value}`,
              query: {
                sum: sum,
                days: days,
                user_id: res?.data?.data?.client_id,
              },
            });
          }
        }
      });
    },
    async sendFeedback() {
      Object.keys(this.feedback_form.fields).map((item) => {
        if (this.feedback_form.fields[item]?.validate) {
          Object.values(this.feedback_form.fields[item].validate).map((el) => {
            el.fn(this.feedback_form.fields[item], ...el.args);
          });
        }
      });

      if (this.feeback_valid) {
        const payload = {
          phone: toServerPhone(this.feedback_form.fields.phone.value),
          fio: this.feedback_form.fields.fio.value,
          message: this.feedback_form.fields.msg.value,
        };
        await this.SEND_FEEDBACK(payload).then((res) => {
          const status = res?.data?.code || res?.status;
          if (status === 200) {
            this.closeDialog("feedback_dialog");
            this.addNotification({
              message: "Форма успешно отправлена",
            });
          } else {
            this.addNotification({
              message: "Что-то пошло не так",
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["TOKEN"]),
    phone_valid() {
      const phone = this.signin_form.fields.phone;
      return !phone.errorBag.length && phone.isDirty ? true : false;
    },
    password_valid() {
      const password = this.signin_form.fields.password;
      return !password.errorBag.length && password.isDirty ? true : false;
    },
    reset_phone_valid() {
      const phone = this.reset_form.fields.phone;
      return !phone.errorBag.length && phone.isDirty ? true : false;
    },
    reset_password_valid() {
      const password = this.reset_form.fields.password;
      return !password.errorBag.length && password.isDirty ? true : false;
    },
    reset_recaptcha_valid() {
      const recaptcha_field = this.reset_recaptcha_token;
      return Boolean(recaptcha_field.token?.length);
    },
    authorized() {
      return this.TOKEN?.length > 10 && this.$route.meta.auth;
    },
    feeback_valid() {
      if (
        Object.values(this.feedback_form.fields).filter(
          (item) => item?.errorBag?.length || (!item?.isDirty && item?.validate)
        ).length
      ) {
        return false;
      } else return true;
    },
  },
  watch: {
    menuToggle(value) {
      if (value) this.menuAnimation.play();
      else this.menuAnimation.reverse();
    },
  },
  mounted() {
    this.initMenu();
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/components/signin_dialog.scss";
.v-header {
  background-color: $white;

  /* .v-header__menu */

  &__menu {
    min-height: 12px;
    min-width: 24px;
    border-radius: 50%;
    display: none;
    position: relative;
    &::after {
      content: "";
      background-color: #f4f8f9;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 2;
      top: 50%;
      left: 50%;
    }
  }

  /* .v-header__menu-line */

  &__menu-line {
    z-index: 3;
    width: 100%;
    height: 2px;
    position: absolute;
    &:nth-child(1) {
      top: 0px;
      background-color: $accent;
      // transform-origin: center;
    }
    &:nth-child(2) {
      top: 60%;
      width: 60%;
      transform: translateY(-50%);
      background-color: $accent;
    }
    &:nth-child(3) {
      top: 100%;
      background-color: $accent;
      // transform-origin: center;
    }
  }

  /* .v-header__nav */

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    padding: 18px 10px;
  }

  /* .v-header__wrapper */

  &__wrapper {
    display: flex;
    flex-grow: 1;
  }

  /* .v-header__links */

  &__links {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  /* .v-header__logo */

  &__logo {
    margin-right: 66px;
    position: relative;
    z-index: 2;
    font-size: 36px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    white-space: nowrap;
    color: $accent;
    cursor: pointer;
  }

  /* .v-header__link */

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-right: 40px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }

  /* .v-header__actions */

  &__actions {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
  }

  /* .v-header__contacts */

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 23px;
  }

  /* .v-header__contacts-title */

  &__contacts-title {
    color: $accent;
    display: none;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  /* .v-header__contact */

  &__contact {
    font-weight: 400;
    font-size: 14px;
  }

  /* .v-header__auth */

  &__auth {
  }
  /* .v-header__lk */

  &__lk {
    display: none;
  }
  /* .v-header__btn */

  &__btn {
    margin-right: 12px;
  }
  /* .v-header__wrapper--active */

  &__wrapper--active {
    display: flex !important;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 280px;
    height: 100vh;
    overflow-y: auto;
    background-color: $grey;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media (max-width: 1200px) {
  .v-header {
    /* .v-header__logo */
    &__logo {
      margin-right: 12px;
    }
  }
}
@media (max-width: 1024px) {
  .v-header {
    /* .v-header__nav */

    &__nav {
      justify-content: initial;
    }

    /* .v-header__menu */

    &__menu {
      display: flex;
      margin-right: 24px;
      position: fixed;
      left: 20px;
      z-index: 6;
    }

    /* .v-header__menu-line */

    &__menu-line {
    }

    /* .v-header__logo */

    &__logo {
      margin-left: 60px;
      flex-grow: 1;
      align-items: flex-start;
      display: flex;
    }

    /* .v-header__links */

    &__links {
      flex-direction: column;
      margin-right: 0;
      align-items: flex-start;
      margin-bottom: 40px;
    }

    /* .v-header__link */

    &__link {
      margin-right: 0;
      font-size: inherit;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    /* .v-header__wrapper */

    &__wrapper {
      display: none;
      padding: 20px 15px;
      padding-top: 100px;
      transform: translateX(-280px);
      flex-direction: column;
      font-size: 16px;
    }

    /* .v-header__actions */

    &__actions {
      flex-direction: column;
      flex-grow: initial;
      align-items: flex-start;
    }

    /* .v-header__contacts */

    &__contacts {
      margin-right: 0;
      margin-bottom: 20px;
    }

    /* .v-header__contacts-title */

    &__contacts-title {
      display: initial;
    }

    /* .v-header__contact */

    &__contact {
      font-size: inherit;
    }

    /* .v-header__lk */

    &__lk {
      display: flex;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    /* .v-header__auth */

    &__auth {
    }
  }
}
@media (max-width: 768px) {
  .v-header {
    /* .v-header__nav */

    &__nav {
    }

    /* .v-header__menu */

    &__menu {
    }

    /* .v-header__menu-line */

    &__menu-line {
    }

    /* .v-header__logo */

    &__logo {
      margin-left: 60px;
    }

    /* .v-header__wrapper */

    &__wrapper {
    }

    /* .v-header__links */

    &__links {
    }

    /* .v-header__link */

    &__link {
    }

    /* .v-header__actions */

    &__actions {
    }

    /* .v-header__contacts */

    &__contacts {
    }

    /* .v-header__contact */

    &__contact {
    }

    /* .v-header__lk */

    &__lk {
    }

    /* .v-header__auth */

    &__auth {
    }
  }
}
@media (max-width: 575px) {
  .v-header {
    /* .v-header__logo */

    &__logo {
      font-size: 24px;
      margin-left: 50px;
      width: 100%;
      svg {
        display: none;
        width: 100%;
        height: auto;
      }
    }
    /* .v-header__nav */

    &__nav {
      flex-wrap: wrap;
    }
    /* .v-header__menu */

    &__menu {
      top: 25px;
    }
    /* .v-header__btn */
    /* .v-header__auth */

    &__btn,
    &__auth {
      margin-top: 16px;
    }
  }
}
@media (max-width: 320px) {
}

.feedback__dialog-descr {
  font-size: 13px;
  margin-bottom: 12px;
}
.feedback__dialog-inp {
  margin-bottom: 12px;
}
</style>

<style lang="scss">
@media (max-width: 575px) {
  .v-header {
    /* .v-header__logo */

    &__logo {
      svg {
        max-width: 156px;
        width: 100%;
        height: auto;
      }
    }
  }
}
.condition {
  /* .condition__dialog-subtitle */

  &__dialog-subtitle {
    font-size: 13px;
    line-height: 130% !important;
    color: $third-grey;
    margin-bottom: 15px !important;
  }

  /* .condition__dialog-btn */

  &__dialog-btn {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
