<template>
  <div
    class="v-select"
    tabindex="0"
    @focusout="closeSelect"
    @click="openSelect"
  >
    <p class="v-select__label" v-if="data.label">
      {{ data.label !== "" ? data.label : "" }}
    </p>

    <!-- <p class="v-select-pre__text" v-if="this.$slots.pre_text">
      <slot name="pre_text"></slot>
    </p> -->

    <div
      class="v-select__value"
      :class="{ 'v-select--error': errorBag.length }"
    >
      <span>
        {{ modelValue !== "" ? modelValue : data?.selected_option }}
      </span>
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{ 'icon-revert': !data.visible }"
      >
        <path
          d="M1 1L7 7L13 1"
          stroke="#F2D038"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <p class="v-select__error">
      {{ errorBag.length ? errorBag[0] : "" }}
    </p>

    <div class="v-select__list" v-show="data.visible">
      <p
        class="v-select__option"
        v-for="(item, index) in items.length ? items : data.options"
        :key="index"
        @click="selectOption(item, data), $emit('update:modelValue', item)"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-select",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: { type: String, default: null },
    errorBag: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    highlight() {
    },
    openSelect(data) {
      if (this.data.visible == true) {
        this.closeSelect();
      } else {
        this.$emit("openSelect", this.data);
      }
    },
    closeSelect() {
      this.$emit("closeSelect", this.data);
    },
    selectOption(option, data) {
      const payload = {
        option: option,
        data: data,
      };
      this.$emit("selectOption", payload);
    },
  },
};
</script>
<style lang="scss">
.v-select {
  position: relative;
  width: 100%;
  outline: none;
  // .v-select__label

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
  }

  // .v-select__value

  &__value {
    font-family: "Lato", sans-serif;
    height: 43px;
    background-color: $sec-grey;
    border-radius: 10px;
    outline: none;
    padding: 11px 15px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      transition: 0.3s;
    }
  }

  // .v-select__error

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 5px;
    font-family: "Lato", sans-serif;
    max-width: 100%;
    white-space: break-spaces;
  }

  // .v-select--error

  &--error {
    border-color: $red;
  }

  // .v-select__list

  &__list {
    position: absolute;
    font-family: "Lato", sans-serif;
    top: 100%;
    left: 0;
    margin-top: 2px;
    background-color: $sec-grey;
    border-radius: 10px;
    outline: none;
    padding: 11px 15px;
    width: 100%;
    z-index: 2;
  }

  // .v-select__option

  &__option {
    cursor: pointer;
    transition: 0.3s;
    margin: 12px 0;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      color: #838383;
    }
  }

  // .v-select-pre__text

  &-pre__text {
    white-space: nowrap;
  }
}
.icon-revert {
  transform: rotate(180deg);
}

@media (max-width: 1600px) {
  .v-select {
    // .v-select__label

    &__label {
      line-height: 25px;
      font-size: 14px;
    }

    // .v-select__value

    &__value {
      height: 40px;
    }

    // .v-select__list

    &__list {
    }

    // .v-select__option

    &__option {
    }
  }
}
@media (max-width: 1200px) {
  .v-select {
    // .v-select__label

    &__label {
      line-height: 25px;
    }

    // .v-select__value

    &__value {
    }

    // .v-select__list

    &__list {
    }

    // .v-select__option

    &__option {
    }
  }
}
@media (max-width: 768px) {
  .v-select {
    // .v-select__label

    &__label {
      font-size: 13px;
    }

    // .v-select__value

    &__value {
    }

    // .v-select__list

    &__list {
    }

    // .v-select__option

    &__option {
    }
  }
}
</style>
