const signup = {
  state: {
    notifications: [],
  },
  mutations: {
    addNotification: (state, notification) => {
      state.notifications.push(notification);
    },
    removeNotification: (state) => {
      state.notifications.shift();
    },
  },
  actions: {
    addNotification: ({ commit }, notification) => {
      commit("addNotification", notification);
    },
    removeNotification: ({ commit }) => {
      commit("removeNotification");
    },
  },
  getters: {
    getNotifications: (state) => {
      return state.notifications;
    },
  },
};

export default signup;
