<template>
  <button :class="{ loading: loader }" class="v-btn">
    <span v-show="loader" ref="loader" class="v-btn__loader"></span>
    <slot v-if="!loader"></slot>
  </button>
</template>
<script>
import { gsap } from "gsap";
export default {
  name: "v-btn",
  methods: {
    animateLoader() {
      const loader = this.$refs.loader;
      if (loader) {
        gsap.to(loader, {
          rotation: "+=360",
          repeat: -1,
          duration: 1,
          ease: "linear",
        });
      }
    },
    stopLoaderAnimation() {
      const loader = this.$refs.loader;
      if (loader) {
        gsap.killTweensOf(loader);
      }
    },
  },
  mounted() {},
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loader: {
      handler(newValue) {
        if (newValue) {
          this.animateLoader();
        } else {
          this.stopLoaderAnimation();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.v-btn {
  @include btn;
  min-height: 40px;
  // .v-btn__name

  &__name {
  }
}

.v-btn.loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.v-btn__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  transform-origin: center;
  height: 24px;
  border: 2px solid $white;
  border-top-color: $dark-blue;
  border-radius: 50%;
  pointer-events: none;
}
</style>
