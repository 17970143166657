<template>
  <component :is="layout">
    <router-view />
  </component>
  <v-notification></v-notification>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import { mapActions } from "vuex";
export default {
  components: {
    DefaultLayout,
    DashboardLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta?.layout || "default") + "-layout";
    },
  },
  methods: {
    ...mapActions(["GET_MORATORIUM_STATUS", "addNotification"]),
  },
  async mounted() {
    const token = localStorage.getItem("token");
    if (token?.length > 10) {
      this.$store.commit("SET_TOKEN", token);
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
